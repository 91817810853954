import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import '../assets/css/order-sign_up.css';
import Constants from "../services/Constants"; 
import UseAllRestaurants from "../hook/UseAllRestaurants";
import PaginationComponent from "../components/PaginationComponent";
import RestaurantLoaderComponent from "../components/RestaurantLoaderComponent";
import { useTranslation } from 'react-i18next';
import UseFavourites from "../hook/UseFavourites";
import Axios from "axios";
import { toast } from 'react-toastify';

export default function ListRestaurantComponent({type}){
  const { t } = useTranslation();
  let userToken = localStorage.getItem('userToken');
  const [currentPage, setCurrentPage] = useState(1);
  const {total_size,limit,offset,restaurants} = UseAllRestaurants(Constants.RESTAURANT_ALL_URL+type+'?offset='+currentPage+'&limit=12');
  const indexOfLastPost = currentPage * limit;
  const indexOfFirstPost = indexOfLastPost - limit;
  const currentPosts = restaurants.slice(indexOfFirstPost, indexOfLastPost);
  const [update,setUpdate] = useState(0);
  const favourites = userToken ? UseFavourites(Constants.FAVOURITES_FOOD_RESTAURANT,update) : {'restaurant':[]};
  const addRemoveFavourites = {food_id : 0,restaurant_id : 0};

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  } 
  useEffect(() => {
  },[restaurants]);

  const wishList = (e,type,id,remove) => {
     e.preventDefault();
    let url = '';
    if(id){
      if(type===0) addRemoveFavourites.food_id = id;
      else addRemoveFavourites.restaurant_id = id; 
      if(remove===0) url = Constants.FAVOUR_ADD_URL;
      else url = Constants.FAVOUR_REMOVE_URL;
      Axios({
        method : remove===0 ? 'post' : 'delete',
        url : url, data : addRemoveFavourites,
        headers: 
        { 'Authorization': 'Bearer '+userToken } 
      }).then((response) => {
        if(response.data.message)
          toast.success(response.data.message);
      }).catch((error) => {
        if(error.response){
          if(error.response.data.message)
            toast.error(error.response.data.message);
        }
      }).finally((error) => {
        setUpdate(update+1);
      })
    }
  }

  return(
      <>
        <div className="container margin_30_20">
          <h5>{total_size} {t('result_for_all_listing')} {localStorage.getItem('userAddress')}</h5>
        </div>
        <div className="container">
          <div className="promo mb_30">
            <h3>{t('restaurants')}</h3>
            <p>{t('free_delivery_description')}</p>
            <i className="icon-food_icon_delivery"></i>
          </div>
          <div className="row isotope-wrapper">
              {restaurants.length > 0 ?
                restaurants.map((restaurant) => {
                  return(
                    <div key={restaurant.id} className="col-xl-4 col-lg-6 col-md-6 col-sm-6 isotope-item delivery">
                    <div className="strip">
                      <figure>
                        <span className="ribbon off">{restaurant.open ? t('open') : t('closed')}{}</span>
                        <img src={Constants.RESTAURANT_URL+restaurant.logo} className="img-fluid lazy" alt="" />
                        <Link to={`/detail/${restaurant.id}`} className="strip_info">
                          <small>{restaurant.veg ? t('veg') : ''}{restaurant.non_veg && <> & {t('non-veg')}</>}</small>
                          <div className="item_title">
                            <h3>{restaurant.name}</h3>
                            <small>{restaurant.address}</small>
                            {userToken!==null ? <li className="score float-right list-none" onClick={(e) => wishList(e,1,restaurant.id,((favourites.hasOwnProperty("restaurant") && favourites.restaurant.includes(restaurant.id.toString())) ? 1 : 0))}>
                            <i className={`red-color ${favourites.hasOwnProperty("restaurant") && favourites.restaurant.includes(restaurant.id.toString()) ? 'icon_heart' : 'icon_heart_alt'} `}></i></li>
                              : (Constants.AUTH ? <li className="float-right" onClick={(e) => {e.preventDefault();toast.error('Please login to continue')}}><i className="red-color icon_heart_alt"></i></li> : '')}
                          </div>
                        </Link>
                      </figure>
                      <ul>
                        <li>{restaurant.take_away && <span className="take yes">Takeaway</span>}
                            {restaurant.delivery && <span className="deliv yes">Delivery</span>}
                        </li>
                        <li className="score"><strong>{restaurant.hasOwnProperty('avg_rating') ? Math.round(restaurant.avg_rating) : 0}</strong></li>
                      </ul>
                    </div>
                  </div>
                  )
                }): <RestaurantLoaderComponent />}
            </div>
          </div>
          {total_size > 10 && <PaginationComponent currentPage={currentPage} postsPerPage={limit}
          totalPosts={total_size} paginate={paginate} page={'restaurants'} />}
      </>
    )
}