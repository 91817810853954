import { useState, useEffect } from "react";
import Axios from "axios";

const UseAllRestaurants = (url) => {
  const [data, setData] = useState({total_size:0,limit:12,offset:0,restaurants:[]});
  const zoneId = localStorage.getItem('zoneId');
  useEffect(() => {
    Axios.get(url,{
       headers: { 'zoneId': zoneId }
    }).then((response)=>{
      if(response.data.restaurants){
        setData(response.data);
      }
    }).catch((error) => {
      console.log(error)
    });
  }, [url,zoneId]);

  return data;
};

export default UseAllRestaurants;