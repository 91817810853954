import React ,{ useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Constants from "../services/Constants";
import UsePopularRestaurant from "../hook/UsePopularRestaurant";
import ListRestaurantLoader from "../components/ListRestaurantLoader";
import { useTranslation } from 'react-i18next';
import UseFavourites from "../hook/UseFavourites";
import Axios from "axios";
import { toast } from 'react-toastify';

export default function RestaurantComponent(){
  let userToken = localStorage.getItem('userToken');
  const [restaurants] = UsePopularRestaurant(Constants.RESTAURANT_POPULAR_URL);
  const [image,setImage] = useState({width:'200',height:'200',fixed:0});
  const imageSize = {width:'150',height:'100',fixed:1};
  const [update,setUpdate] = useState(0);
  const favourites = userToken ? UseFavourites(Constants.FAVOURITES_FOOD_RESTAURANT,update) : {'restaurant':[]};
  const { t } = useTranslation();
  const addRemoveFavourites = {food_id : 0,restaurant_id : 0};

  useEffect(() => {
  },[restaurants])


  const addDefaultSrc = (e) => {
    e.target.src = require('../assets/images/no_image_restaurant.jpg');
    if(image.fixed===0) setImage(imageSize);
  }

  const wishList = (e,type,id,remove) => {
     e.preventDefault();
    let url = '';
    if(id){
      if(type===0) addRemoveFavourites.food_id = id;
      else addRemoveFavourites.restaurant_id = id; 
      if(remove===0) url = Constants.FAVOUR_ADD_URL;
      else url = Constants.FAVOUR_REMOVE_URL;
      Axios({
        method : !remove ? 'post' : 'delete',
        url : url, data : addRemoveFavourites,
        headers: { 'Authorization': 'Bearer '+userToken } 
      }).then((response) => {
        if(response.data.message) toast.success(response.data.message);
      }).catch((error) => {
        if(error.response){
          if(error.response.data.message) toast.error(error.response.data.message);
        }
      }).finally((error) => {
        setUpdate(update+1);
      })
    }
  }



  return(
    <>
      {restaurants.length > 0 ?
        <div className="row add_bottom_25">
          {restaurants.map((restaurant,index) => {
            return (
              <div className={`col-lg-6 retsaurant-info-${index+1}`} key={restaurant.id} >
                <div className="list_home">
                  <ul>
                    <li>
                      <Link to={`/detail/${restaurant.id}`}>
                        <figure>
                          <img width={image.width} height={image.height} onError={addDefaultSrc} 
                          src={Constants.RESTAURANT_URL+restaurant.logo} 
                          alt={restaurant.name} className="lazy" />
                        </figure>
                        <em>{restaurant.veg ? t('veg') : ''}</em>
                        {restaurant.non_veg && <em> & {t('non-veg')}</em>}
                        <div className="flex-rating float-right">
                          <ul className={`avg-rating-${restaurant.id}`} avg-rating={restaurant.avg_rating}>
                            {Constants.RATING_COUNT && 
                              Constants.RATING_COUNT.map(function(key){
                              return <li key={key} className="score"><strong className={`${restaurant.avg_rating>=key ? '' :'color-red'}`}></strong></li>
                            })}
                            <li>({(restaurant.hasOwnProperty('count')) ? Math.round(restaurant.count) : 0})</li>
                          </ul>
                        </div>
                        <h3>{restaurant.name.length > 10 ? restaurant.name.substring(0,10)+'...' : restaurant.name}</h3>
                        <small>{restaurant.address.length > 20 ? restaurant.address.substring(0,20)+'....' : restaurant.address}</small>
                        <ul>
                          <li><span className="ribbon off">{restaurant.open ? t('open') : t('closed')}</span></li>
                          <li>
                            <span className="deliv yes">Delivery</span>
                            &nbsp; {restaurant.delivery_time} &nbsp; 
                            {restaurant.delivery_time ? t('mins') : ''}
                            </li>
                          {userToken!==null ? <li className="float-right" onClick={(e) => wishList(e,1,restaurant.id,((favourites.hasOwnProperty("restaurant") && favourites.restaurant.includes(restaurant.id.toString())) ? 1 : 0))}>
                          <i className={`red-color ${favourites.hasOwnProperty("restaurant") && favourites.restaurant.includes(restaurant.id.toString()) ? 'icon_heart' : 'icon_heart_alt'} `}></i></li>
                          : (Constants.AUTH ? <li className="float-right" onClick={(e) => {e.preventDefault();toast.error('Please login to continue')}}><i className="red-color icon_heart_alt"></i></li> : '')}
                        </ul>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
          )})}
        </div>
      : <ListRestaurantLoader/>}
    </>
  )
}


