import "../assets/css/review.css";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

export default function ReviewComponent({openPage,addDefaultSrc}){
  const { id } = useParams();
  const { t } = useTranslation();
  const [rating1, setRating1] = useState(0);
  const [rating2, setRating2] = useState(0);
  const { register: register1,handleSubmit:handleSubmit1,setError:setError1,setValue:setValue1,formState: { errors: errors1 } } = useForm();
  const { register: register2,handleSubmit:handleSubmit2,setError:setError2,setValue:setValue2,formState: { errors: errors2 }} = useForm();

  const onSubmit1 = (data1) => {console.log('data1',data1)}
  const onSubmit2 = (data2) => {console.log('data2',data2)}


  return(
      <main className="bg_gray">
        <div className="container margin_60_20">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <button className="tablink" style={{'backgroundColor':'#777'}} id="tablink-food" onClick ={() => openPage('food')}>{t('food_review')}</button>
              <button className="tablink" id="tablink-restaurant" onClick ={() => openPage('restaurant')}>{t('restaurant_review')}</button>
              <div key={id} id="food" className="review_card box_general write_review text-center">
                <form onSubmit={handleSubmit1(onSubmit1)}>
                  <label className="d-block add_bottom_15">Rate the Food</label>
                  <img onError={(e) => addDefaultSrc(e,"food")} alt={"Food"} src={localStorage.getItem('reviewImage')} className="rounded-circle img-thumbnail food-wdth d-block m-auto" />
                  <div className="row justify-content-center d-flex" >
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          type="button"
                          key={index}
                          className={index <= rating1 ? "rating star-select" : "rating star-unselect"}
                          onClick={() => setRating1(index)}
                        ><span className={index <= rating1 ? "star-size star-select" : "star-size star-unselect"}>&#9733;</span>
                        </button>
                      )})}
                      <input {...register1('rating1',{required:true})} defaultValue={rating1===0 ? '' : rating1} type="hidden" />
                  </div>
                  {(errors1.rating1?.type==="required" && rating1===0) && <p className="error">Rating Cannot be blank</p>}
                  <div className="form-group">
                    <label>Write your review here...</label>
                    <textarea {...register1('content1',{required:true})} className="form-control mb-4"  placeholder="Write your review to help others learn about this food review"></textarea>
                    {errors1.content1?.type==="required" && <p className="error">Review Cannot be blank</p>}
                  </div>
                  <button type="submit" className="btn_1">Submit</button>&nbsp;
                  <button type="cancel" className="btn_1">Cancel</button>
                </form>
              </div>
              <div id="restaurant" className="review_card box_general write_review" style={{'textAlign':'center','display': 'none'}}>
                <form onSubmit={handleSubmit2(onSubmit2)}>
                  <label className="d-block add_bottom_15">Rate the Food</label>
                  <img onError={(e) => addDefaultSrc(e,"restaurant")} alt={"Restaurant"} src={localStorage.getItem('reviewImage')} className="d-block m-auto rounded-circle img-thumbnail food-wdth" />
                  <div className="row justify-content-center d-flex">
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          type="button"
                          key={index}
                          className={index <= rating2 ? "rating star-select" : "rating star-unselect"}
                          onClick={() => setRating2(index)}
                        ><span className={index <= rating2 ? "star-size star-select" : "star-size star-unselect"}>&#9733;</span>
                        </button>
                      )})}
                      <input {...register2('rating2',{required:true})} defaultValue={rating2===0 ? '' : rating2} type="hidden" />
                  </div>
                  {(errors2.rating2?.type==="required" && rating2===0) && <p className="error">Rating Cannot be blank</p>}
                  <div className="form-group">
                      <label>Write your review here...</label>
                      <textarea {...register2('content2',{required:true})} className="form-control mb-4"  placeholder="Write your review to help others learn about this food review"></textarea>
                      {errors2.content2?.type==="required" && <p className="error">Review Cannot be blank</p>}
                  </div>
                  <button type="submit" className="btn_1">Submit</button>&nbsp;
                  <button type="cancel" className="btn_1">Cancel</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
  )
}