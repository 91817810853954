import React ,{ useEffect,useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';
import { Link } from "react-router-dom";
import Constants from "../services/Constants";
import UseCategory from "../hook/UseCategory";
import CategoryLoaderComponent from "../components/CategoryLoaderComponent";

export default function CategoryComponent({loading,setLoading}){
  
  const [categories] = UseCategory(Constants.CAT_API_URL);
  const [image,setImage] = useState({width:'200',height:'200',fixed:0});
  const imageSize = {width:'100',height:'100',fixed:1};
  let noofcategory = 5;
  useEffect(() => {
    setLoading(1);
  },[categories,setLoading])

  const addDefaultSrc = (e) => {
    e.target.src = require('../assets/images/no_image_food.jpg');
    if(image.fixed===0){
      setImage(imageSize);
    }
  }

  if(window.innerWidth >= 320 && window.innerWidth <= 360){
    noofcategory = 1;
  }
  if(window.innerWidth >= 361 && window.innerWidth <= 768){
    noofcategory = 2;
  }
  if(window.innerWidth >= 769 && window.innerWidth <= 1200){
    noofcategory = 3;
  }
  if(window.innerWidth >= 1201){
    noofcategory = 4;
  }

  return(
    <>
      {categories.length > 0 ?
        <OwlCarousel className="owl-theme" items={noofcategory} autoplay dots loop>
          {categories.map((category,index) => {
            return (
              <div key={category.id} className="item_version_2 mr-2">
              <Link to={`/listing/${category.id}/${window.btoa(category.name)}`}>
                <figure>
                    <img onError={addDefaultSrc} src={Constants.CATEGORY_URL+category.image} 
                    alt={category.name} width={image.width} height={image.height} />
                    <div className="info">
                      <h3>{category.name}</h3>
                    </div>
                </figure>
              </Link>
            </div>
            )
          })}
        </OwlCarousel>
      : <CategoryLoaderComponent />}
    </>
  )
}
