import { useEffect,useState,useCallback } from "react";
import { Link,useNavigate } from "react-router-dom";
import '../assets/css/order-sign_up.css';
import Constants from "../services/Constants"; 
import Axios from "axios";
import '../assets/css/home.css';
import '../assets/css/style.css';
import '../assets/css/bootstrap_customized.min.css';
import { useTranslation } from 'react-i18next';

export default function HeaderComponent(){
  var style,menu,sidemenu = {};
  var unhide = {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show,setShow] = useState(false);
  const [logo,setLogo] = useState("");
  useEffect(() => {
    Axios.get(Constants.CONFIG_URL).then((response) => {
      if(response.data){
        setLogo(response.data.hasOwnProperty('logo') ? Constants.BUSINESS_URL+response.data.logo : "");
        localStorage.setItem('web',JSON.stringify(response.data));
        localStorage.setItem('logo',Constants.BUSINESS_URL+response.data.logo);
        if(!localStorage.hasOwnProperty('zoneId')) localStorage.setItem('zoneId',Constants.DEFAULT_ZONE_ID);
      }
    })
  },[]);

  const addDefaultSrc = (ev) => {
    ev.target.src = require('../assets/images/no_image.png');
  }

  const logout = () => {
    for (var i = 0; i < Constants.ClearStorage.length; i++) {
      localStorage.removeItem(Constants.ClearStorage[i]);
    }
    showDropdown();
    navigate('/login');
  }

  const showDropdown = useCallback(() => {
    let hide = show ? false : true;
    setShow(hide);
  },[show])

  if(window.innerWidth >= 320 && window.innerWidth <= 768){
    style = {
      'position': 'absolute',
      'transform': 'translate3d(-95px, 19px, 0px)',
      'top': '0px', 'left': '0px',
      'willChange': 'transform',
    };
    menu = {
      'display' : 'none'
    }
    unhide = {
      'display' : 'block'
    }

    sidemenu = {
      'display' : 'block'
    }
  }else{
    sidemenu = {
      'display' : 'inline-block'
    }
    unhide = {
      'display' : 'none'
    }
  }


  const setClear = () => {
    localStorage.setItem('zoneId',0);
    localStorage.removeItem('userAddress');
    localStorage.removeItem('location');
    localStorage.removeItem('lat');
    localStorage.removeItem('lng');
    localStorage.removeItem('place');
    localStorage.removeItem('latlng');
    return 1;
  }
    

  return (
      <header className="header black_nav clearfix element_to_stick">
        <div className="container">
          <div id="logo">
            <Link to="/">
              <img src={logo!=='' ? logo : localStorage.getItem('logo')} width="162" height="35" alt="" />
            </Link>
          </div>
          <div className="layer"></div>
            <ul id="top_menu">
              {!localStorage.getItem('userToken') &&
                <>
                {localStorage.getItem('zoneId') && <li style={menu} className="show-submenu  mt-2"><Link to="/restaurants" className="register bold"><h6>{t('all_restaurants')}</h6></Link></li>}
                {Constants.AUTH ? <><li><Link to="/register" className="register" title="Register"><i className="icon-user_2"></i></Link></li>
                <li><Link to="/login" id="sign-in" className="login" title="Login">Sign In</Link></li>
                <li style={{'display':'none'}}><Link style={{'display': `${Constants.CLEAR_ZONE}`,'fontSize':'26px','marginTop':'5px'}} onClick={() => setClear()} ><i className="icon_stop_alt"></i></Link></li></>
                : ''}
                </>
              }
              {localStorage.getItem('userToken') &&
                <ul id="top_menu" className="drop_user">
                  <li>
                    <div className={`dropdown user clearfix ${show ? "show" : "hide"}`}>
                      <a onMouseDown={() => showDropdown()} href="#/" data-toggle="dropdown" aria-expanded={show}>
                        <figure><img onError={addDefaultSrc} src={localStorage.getItem('userImage') ? localStorage.getItem('userImage') : require('../assets/images/no_image.png')} alt={localStorage.getItem('userName') || ''} /></figure>
                        <span>{localStorage.getItem('userName')}  </span>
                      </a>
                      <div className={`dropdown-menu  ${show ? "show" : "hide"}`} style={show ? style : {}}>
                        <div className="dropdown-menu-content">
                          <ul onClick={() => logout()}>
                            <li className="logout"><i className="fa fa-sign-out"></i> 
                             &nbsp; Logout </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>}
            </ul>
            <a href="#0" className="open_close">
                <i className="icon_menu"></i><span>{t('menu')}</span>
            </a>
            <nav className="main-menu">
              <div id="header_menu">
                <a href="#0" className="open_close">
                  <i className="icon_close"></i><span>Menu</span>
                </a>
                <a href="index.html"><img src={localStorage.getItem('logo')} width="162" height="35" alt="" /></a>
              </div>
              {(localStorage.getItem('userToken')=='' && localStorage.getItem('zoneId')) &&
                <ul>
                <li className="submenu" style={sidemenu}>
                  <Link to="/restaurants" className="show-submenu">{t('all_restaurants')}</Link>
                </li>
                </ul>
              }
              {localStorage.getItem('userToken') &&
              <ul>
                {localStorage.getItem('zoneId') && <li className="submenu" style={sidemenu}>
                  <Link to="/restaurants" className="show-submenu">{t('all_restaurants')}</Link>
                </li>}
                <li className="submenu">
                    <Link to="/account" className="show-submenu">{t('my_account')}</Link>
                </li>
                <li className="submenu">
                    <Link to="/orders" className="show-submenu">{t('my_orders')}</Link>
                </li>
                <li className="submenu">
                    <Link to="/address" className="show-submenu">{t('address_book')}</Link>
                </li>
                <li className="submenu">
                    <Link to="/favourites" className="show-submenu">{t('my_favourites')}</Link>
                </li>
              </ul>}
            </nav>
          </div>
      </header>
    )
  }
