import React from 'react';
import { Link,useLocation } from "react-router-dom";

const Pagination = ({ postsPerPage, totalPosts, paginate,currentPage,page }) => {
  const location = useLocation();
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }


  return (
    <div className="pagination_fg">
      <Link to={`/${page}`} onClick={() => paginate(1)}>First</Link>
      <Link to={`/${page}`} onClick={() => paginate((0 === currentPage - 1) ? currentPage : currentPage - 1)}>&lt;&lt; Previous</Link>
      {pageNumbers.map(number => (
        <Link onClick={() => paginate(number)} to={`/${page}`} key={number} className={`${number===currentPage ? 'active' : ''}`}>{number}</Link>
      ))}
      <Link to={`/${page}`} onClick={() => paginate((pageNumbers.length >= currentPage + 1) ? currentPage + 1 : (pageNumbers.length < currentPage ? currentPage + 1 : currentPage))}>Next &gt;&gt;</Link>
      <Link onClick={() => paginate(pageNumbers.length)} to={`/${page}`}>Last</Link>
    </div>
  );
};

export default Pagination;
