import $ from 'jquery'; 
import { useState } from "react";
import { useLocation,Link } from "react-router-dom";
import Constants from "../services/Constants";

export default function FooterComponent(){

  const location = useLocation();
  const [footerdata] = useState(localStorage.getItem('web')!==null ? JSON.parse(localStorage.getItem('web')) : {
    business_name : '',logo : '',address:'',phone:'',email:'',base_urls: {},country:'',default_location:{},currency_symbol:'',
    currency_symbol_direction:'',app_minimum_version_android:'',app_url_android:'',app_minimum_version_ios:'',app_url_ios:'',
    customer_verification:'',schedule_order:'',order_delivery_verification:'',cash_on_delivery:'',digital_payment:'',
    terms_and_conditions:'',privacy_policy:'',about_us:'',per_km_shipping_charge:'',minimum_shipping_charge:'',
    free_delivery_over:'',demo:'',maintenance_mode:'',order_confirmation_model:'',popular_food:'',
    popular_restaurant:'',new_restaurant:'',most_reviewed_foods:'',show_dm_earning:'',canceled_by_deliveryman:'',
    canceled_by_restaurant:'',timeformat:'', language:'',toggle_veg_non_veg:'',toggle_dm_registration:'',
    toggle_restaurant_registration:'' })

  
  $('.btn-number').click(function(e){
    e.preventDefault();
    var fieldName = $(this).attr('data-field');
    var type      = $(this).attr('data-type');
    var input = $("input[name='"+fieldName+"']");
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
        if(type === 'minus') {
            
            if(currentVal > input.attr('min')) {
                input.val(currentVal - 1).change();
            } 
            if(parseInt(input.val()) === input.attr('min')) {
                $(this).attr('disabled', true);
            }

        } else if(type === 'plus') {

            if(currentVal < input.attr('max')) {
                input.val(currentVal + 1).change();
            }
            if(parseInt(input.val()) === input.attr('max')) {
                $(this).attr('disabled', true);
            }

        }
    } else {
        input.val(0);
    }
});
  if(location.pathname==='/'){
      $('.black_nav').removeClass("header_in");
      $('.black_nav').addClass("header");
      $(window).on('scroll', function () {
          if ($(this).scrollTop() > 1) {
              $('.element_to_stick').addClass("sticky");
          } else {
              $('.element_to_stick').removeClass("sticky");
          }
      });
      $(window).scroll();
    }else{
      $('.black_nav').removeClass("header");
      $('.black_nav').addClass("header_in");
    }

    $('a.open_close').on("click", function () {
        $('.main-menu').toggleClass('show');
        $('.layer').toggleClass('layer-is-visible');
    });
    $('a.show-submenu').on("click", function () {
        $(this).next().toggleClass("show_normal");
    });

    var $headingFilters = $('.filter_type h4 a');
    $headingFilters.on('click', function () {
        $(this).toggleClass('opened');
    })
    $headingFilters.on('click', function () {
        $(this).toggleClass('closed');
    });

    $('a.open_filters').on("click", function () {
        $('.filter_col').toggleClass('show');
        $('.layer').toggleClass('layer-is-visible');
    });

    function alignString(str) {
      var i, frags = str.split('-');
      for (i=0; i<frags.length; i++) {
        frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
      }
      return frags.join(' ');
    }


    const onChange = () => {}
    
    return(
      <>
        <footer>
          <div className="container">
            <hr />
            <div className="row">
              <div className="col-lg-6">
                <ul className="footer-selector clearfix">
                  <li>
                    <div className="styled-select lang-selector d-none">
                      <select onChange={ onChange }>
                        <option defaultValue="French">French</option>
                        <option defaultValue="Spanish">Spanish</option>
                        <option defaultValue="Russian">Russian</option>
                      </select>
                    </div>
                  </li>
                  <li className="d-none">
                    <div className="styled-select currency-selector">
                      <select onChange={ onChange }>
                        <option defaultValue="Euro">Euro</option>
                      </select>
                    </div>
                  </li>
                  <li><img src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==" data-src="img/cards_all.svg" alt="" width="230" height="35" className="lazy" /></li>
                </ul>
              </div>
              <div className="col-lg-6">
                <ul className="additional_links">
                  {Constants.FOOTER_MENU.map((menu,index) => {
                    return <li key={index+1}><Link to={menu}>{alignString(menu)}</Link></li>
                  })}
                  <li><span>© CopyRight </span></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </>
    )
}