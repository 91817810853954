import React,{ useEffect,useRef,useState } from "react";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";
import UseAddress from "../hook/UseAddress";
import Constants from "../services/Constants";
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Axios from "axios";

 function AddToCartComponent({sum,resId,itemMenu,setItemMenu,restaurant,showAmount,showVariantAmount}){
  var tagId,attr,isSymbol;
  const fieldRef = useRef();
  const userToken = localStorage.getItem('userToken');
  const [submit,setSubmit] = useState(0)
  const resDetail = restaurant.hasOwnProperty("schedule_order") ? restaurant : {delivery_charge:0,schedules:[],schedule_order:false};
  const carts = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : [];
  const [addresses] = userToken ? UseAddress(Constants.GETADDRESS_URL,0) : [[]];    
  const { register, handleSubmit,setValue,clearErrors,getValues,setError,formState : {errors} } = useForm();
  const { register : register1, handleSubmit : handleSubmit1,setValue:setValue1,clearErrors : clearErrors1,getValues:getValues1,setError:setError1,formState : {errors : errors1} } = useForm();
  const getMinutes = s => s.slice(0, 2) * 60 + +s.slice(-2)
  const getTime = m => new Date(m * 6e4).toJSON().slice(11, 16)
  const web = localStorage.getItem('web')!==null ? JSON.parse(localStorage.getItem('web')) : [{cash_on_delivery:true,digital_payment:false}]
  const setItemQuantity = (itemId,qty) => {
    let filterItem = [];
    console.log(qty===0 && itemMenu.length > 0);
    if(qty===0){
      if(carts.length > 0){
        filterItem = carts.filter((item) => item.id!==itemId);
      }else{
        if(itemMenu.length > 0) filterItem = itemMenu.filter((item) => item.id!==itemId);
      }
        toast.success('Cart updated successfully');
        if(carts.length > 0 && filterItem.length===0){
          localStorage.setItem('sum',0);
          localStorage.setItem('cart',JSON.stringify(filterItem));
        } 
        setItemMenu(filterItem);
    }else{
      setItemMenu(items =>
        items.map(item => {
          if (item.id === itemId) { return {...item, quantity: qty} }
          return item;
        }),
      );
      itemMenu.map(item => {
          if (item.id === itemId) { return {...item, quantity: qty} }
          return item;
      });
      localStorage.setItem('cart',JSON.stringify(itemMenu));
    }
  }
 

  const getTimeSlots = (starts,end,minDifference,day,schedules) => {
    var result = [];
    if(schedules.hasOwnProperty("opening_time") || schedules.hasOwnProperty("closing_time")){
      var start = getMinutes(starts), end = getMinutes(end);
      result.push(`<select ref={fieldRef} class="container" name="schedule_time" required="true">`);
      for (var m = start; m <= end - 60; m += 30){
        let getHour = getTime(m).split(':');
        let getToday = new Date().toLocaleTimeString('en-US',{hour12: false});
        let getTodayHour = getToday.split(':');
        if(getHour[0]>=parseInt(getTodayHour[0])) 
        result.push('<option  value="'+getTime(m) + '-' + getTime(m + 60)+'">'+getTime(m) + '-' + getTime(m + 60)+'</option>');
        else
         if(new Date().getDay()!==day) result.push('<option value="'+getTime(m) + '-' + getTime(m + 60)+'">'+getTime(m) + '-' + getTime(m + 60)+'</option>');
      } 
      result.push('</select>');
    }
    return result;
  }

  const setTimePeriods = (time) => { console.log(time) }

  if(submit){
    const inputRefs = document.querySelectorAll("#checkout input");
    console.log(inputRefs) 
    for (let i = 0; i < inputRefs.length; i++) {
      if (!inputRefs[i].value && inputRefs[i].required) {
        inputRefs[i].focus();
        break;
      }
    }
  }

  console.log('errors',errors);

  const onSubmit = (data) => {
    console.log('data',data);
    console.log('fieldRef.current',fieldRef.current);
  }

  useEffect(() => {
    if(sum) localStorage.setItem('sum',sum);
    if(!localStorage.getItem('previousResId')) localStorage.setItem('previousResId',resId);
    else localStorage.setItem('currentResId',resId);
    if(!localStorage.getItem('deliveryFees')) localStorage.setItem('deliveryFees',resDetail.delivery_charge);
    if(itemMenu.length > 0) localStorage.setItem('cart',JSON.stringify(itemMenu));
  },[tagId,attr,isSymbol,itemMenu,sum,resDetail.delivery_charge,resId]);

  const setTimeSlot = (e) => {  
      let value = Number(e.target.value);
      $('#'+'timeslot-'+value).css({'display':'block'});
      $('#'+'timeslot-'+(value+1)).css({'display':'none'});
      $('#'+'timeslot-'+(value-1)).css({'display':'none'});
  }

  const applyCouponCode = (coupon) => {
    console.log('coupon',coupon);
    if(coupon.length===0) setError1("coupon", { type: "required" });
    else clearErrors1('coupon','required')
    if(getValues1('coupon')!==''){
      Axios.get(Constants.APPLY_COUPON_CODE+'?code='+getValues1('coupon')+'&restaurant_id='+restaurant.id,{
         headers: { 'Authorization': 'Bearer '+userToken }
      }).then((response) => {
        if(response){
          toast.success("Coupon were applied successfully");
        }
      }).catch((error) => {
       if(error){
          if(typeof error.response.data.errors === 'object'){
            var Arr = error.response.data.errors;
            var message = '';
            for (var i = 0; i < Arr.length; i++) { 
              if(Arr[i].code!=='' && Arr[i].message!==''){
                message = Arr[i].message.charAt(0).toUpperCase() + Arr[i].message.slice(1);
                toast.error(message); 
              } 
            }
          }
        }
      });
    }
  }

  return (
    <>
    {Constants.AUTH ? 
    <div className="col-lg-4 mt-5" id="sidebar_fixed">
      <div className="box_order mobile_fixed">
        <div className="head">
          <h3>Order Now</h3>
          <a href="#0" className="close_panel_mobile"><i className="icon_close"></i></a>
        </div>
        <div className="main">
          <ul className="clearfix">
            {itemMenu.length > 0 &&
              itemMenu.map((cart,index) => {
                if (cart.name==="") return null; 
                return (
                  <li key={index+1}>
                    <div className="row">
                      <Link className="col-md-8" to=""> <i className="icon_minus_alt2 minus-icon" onClick={() => setItemQuantity(cart.id,Number(cart.quantity) - 1)}></i> &nbsp; {cart.quantity} &nbsp; <i className="icon_plus_alt2 plus-icon" onClick={() => setItemQuantity(cart.id,Number(cart.quantity) + 1)}></i>   x {cart.name}</Link> 
                      <span className="col-md-4" id="dataAmount" data-amount={cart.variants.length > 0 ? 0 : parseInt(cart.quantity) * parseInt(cart.price)}>{cart.variants.length > 0 ? '' : showAmount(parseInt(cart.quantity) * parseInt(cart.price))}</span>
                      {cart.variants.length > 0 &&
                        cart.variants.map((variant,index) => {
                        if (!variant.title) return null; 
                        return (
                          <div className="ml-2 width-100" key={index+1}>
                            <div className="width-70 float-left">
                            <small key={index+1}>{variant.title} : {variant.type}</small>
                            </div>
                            <div className="width-30 float-right text-right">
                            <small id="dataAmount" data-amount={variant.price}>{variant.price}</small>
                            </div>
                          </div>
                      )})}
                      {cart.add_on_ids.length > 0 &&
                      cart.add_on_ids.map((addon,index) => {
                      return (
                        <div className="ml-2 width-100" key={index+1}>
                          <div className="width-70 float-left">
                          <small key={index+1}>{addon.quantity} x {addon.name}</small>
                          </div>
                          <div className="width-30 float-right text-right">
                          <small id="dataAmount" data-amount={addon.price * addon.quantity}>{showAmount(addon.price * addon.quantity)}</small>
                          </div>
                        </div>
                      )})}
                    </div>
                  </li>                              
              )})}
              {(localStorage.getItem('previousResId') !== localStorage.getItem('currentResId') && (carts.length > 0 && itemMenu.length===0)) &&
              carts.map((cart,index) => {
                if (cart.name==="") return null; 
                return (
                  <li key={index+1}>
                    <div className="row">
                      <Link className="col-md-8" to=""> <i className="icon_minus_alt2 minus-icon" onClick={() => setItemQuantity(cart.id,Number(cart.quantity) - 1)}></i>&nbsp;{cart.quantity}&nbsp;<i className="icon_plus_alt2 plus-icon" onClick={() => setItemQuantity(cart.id,Number(cart.quantity) + 1)}></i>   x {cart.name}</Link> 
                      <span className="col-md-4" id="dataAmount" data-amount={cart.variants.length > 0 ? 0 : parseInt(cart.quantity) * parseInt(cart.price)}>{cart.variants.length > 0 ? '' : showAmount(parseInt(cart.quantity) * parseInt(cart.price))}</span>
                        {cart.variants.length > 0 &&
                          cart.variants.map((variant,index) => {
                          if (!variant.title) return null; 
                          return (
                          <div className="ml-2 width-100" key={index+1}>
                            <div className="width-70 float-left">
                            <small key={index+1}>{variant.title} : {variant.type}</small>
                            </div>
                            <div className="width-30 float-right text-right">
                            <small id="dataAmount" data-amount={variant.price}>{showAmount(variant.price)}</small>
                            </div>
                          </div>
                        )})}
                        {cart.add_on_ids.length > 0 &&
                          cart.add_on_ids.map((addon,index) => {
                          return (
                          <div className="ml-2 width-100" key={index+1}>
                            <div className="width-70 float-left">
                            <small key={index+1}>{addon.quantity} x {addon.name}</small>
                            </div>
                            <div className="width-30 float-right text-right">
                            <small id="dataAmount" data-amount={addon.price * addon.quantity}>{showAmount(addon.price * addon.quantity)}</small>
                            </div>
                          </div>
                        )})}
                    </div>
                  </li>                              
              )})}
          </ul>
          <ul className="clearfix" >
            <li>Subtotal<span>{!isNaN(sum) ? showAmount(sum) : showAmount(0)}</span></li>
            <li>Delivery fee<span> {showAmount(typeof resDetail === 'object' ? resDetail.delivery_charge : 0)}</span></li>
            <li className="total">Total<span>{showAmount(sum + (typeof resDetail === 'object' ? resDetail.delivery_charge : 0))}</span></li>
          </ul>
         <div className="input-group mb-2">
           <input type="text" 
           {...register1('coupon',{required:true})}  onChange={(e) => setValue1('coupon',e.target.value)} 
           className="form-control" id="apply_promocode" defaultValue="" placeholder="Enter Coupon Code" />
           <div className="input-group-append">
              <button onClick ={() => applyCouponCode(getValues1('coupon'))} className="btn_1 gradient full-width" type="button" id="button-addon2"><i className="icofont-sale-discount"></i> Apply</button>
            </div>
          </div>
            {errors1.coupon?.type==="required" && <span className="error">Coupon cannot be blank</span>}
          <form id="checkout" onSubmit={handleSubmit(onSubmit)} >
            <div className="mb-2">
              <div className="row opt_order">
              <label className="col-12">Delivery Type</label>
                {(Constants.ORDER_TYPE.length > 0) &&
                  Constants.ORDER_TYPE.map((order_type,index) => {
                    return(
                      <div className="col-6" key={index+1}>
                        <label className="container_radio">{order_type.charAt(0).toUpperCase()+order_type.slice(1)}
                          <input
                          {...register('order_type',{required:true})} onClick={() => setValue('order_type',order_type)}
                           type="radio" defaultValue={order_type} /><span className="checkmark"></span>
                        </label>
                      </div>
                    )
                })}
                {errors.order_type?.type==="required" && <span className="error ml-3">Delivery Type cannot be blank</span>}
              </div>
            </div>
            <div className="row opt_order">
            <label className="col-12">Preferred Day</label>
            {Constants.ORDER_DAYS_COUNT.length > 0 && 
              Constants.ORDER_DAYS_COUNT.map((order_days,index) => {
              if(new Date().getDay() === order_days.day || new Date().getDay()+1===order_days.day){
                return(
                  <>
                  <div className="col-6" key={index+1}>
                    <label className="container_radio">{`${new Date().getDay() === order_days.day ? 'Today' : 'Tomorrow'}`}
                      <input
                      {...register('order_days',{required:true})} onClick={(e) => setTimeSlot(e)}
                       type="radio" defaultValue={order_days.day} /><span className="checkmark"></span>
                    </label>
                  </div>
                  </>
                )
              }
            })} 
            {errors.order_days?.type==="required" && <span className="error ml-3">Order Type cannot be blank</span>}
            </div>
            <div className="row opt_order">
              <label className="col-12">Preferred Time</label>
              {(resDetail!==null && resDetail.schedule_order) ? 
                ((resDetail!==null && resDetail.schedules.length > 0) ?
                resDetail.schedules.map((schedule,index) => {
                  if(new Date().getDay()===schedule.day || new Date().getDay()+1===schedule.day){
                    return (
                      <div className="container" style={{'display' : `${new Date().getDay()===schedule.day ? 'block' : 'none'}`}} key={index+1} id={`timeslot-${schedule.day}`} key={index+1} dangerouslySetInnerHTML={ { __html: getTimeSlots(schedule.opening_time,schedule.closing_time, 30,schedule.day,schedule) } }></div>
                    )}}) : '')
                : ''}
            </div>
            <div style={{'marginTop':'-20px'}}>
              <label className="col-12 p-0 mt-4">Delivery Here</label>
              {addresses.length > 0 &&
               addresses.map((address,index) => {
                return(
                <div key={index+1}>
                  <label className="container_radio"><b>{Constants.ADDRESS_TYPES.includes(address.address_type) ? address.address_type.charAt(0).toUpperCase()+address.address_type.slice(1) : ''}</b> - <br />
                  {address.address}
                    <input type="radio" defaultValue={address.address} {...register('address',{required:true})}/>
                    <span className="checkmark"></span>
                  </label>
                </div>
                )
              })}
              {errors.address?.type==="required" && <span className="error">Address cannot be blank</span>}
            </div>
            <div className="btn_1_mobile">
              <button type="submit" onClick={() => setSubmit(1)} className="btn_1 gradient full-width mb_5" >Order Now</button>
            </div>
          </form>
        </div>
      </div>
      <div className="btn_reserve_fixed"><a href="#0" className="btn_1 gradient full-width">View Basket</a></div>
    </div>
    : 
    <div className="col-lg-4 mt-5" id="sidebar_fixed">
      <div className="box_order mobile_fixed">
        <div className="head">
          <h3>Order Now</h3>
          <a href="#0" className="close_panel_mobile"><i className="icon_close"></i></a>
        </div>
        <div className="main">
          <ul className="clearfix">
            <li>
              <div className="row d-flex justify-content-center text-center align-items-center">
                <a className="col-md-12" href="https://play.google.com/store/apps/details?id=com.baza.baza_customer" target="_blank">
                <img className="width-20" src={require(`../assets/images/android.png`)} /></a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a className="col-md-12" href="https://apps.apple.com/app/baza-burundi/id1659178968" target="_blank">
                <img className="width-20" src={require(`../assets/images/iphone.png`)} /></a>
                <h6 className="mt-4">Please download the app to order now</h6>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>}
    </>
    )
}


export default AddToCartComponent;