import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import TimeslotComponent from './components/TimeslotComponent';
import InfoComponent from './views/info';
import IndexComponent from './views/index';
import LoginComponent from './views/login';
import ErrorComponent from './views/error';
import ReviewComponent from './views/review';
import VerifyComponent from './views/verify';
import DetailComponent from './views/detail';
import MyOrderComponent from './views/orders';
import ContactComponent from './views/contact';
import ConfirmComponent from './views/confirm';
import RegisterComponent from './views/register';
import MyAddressComponent from './views/address';
import MyAccountComponent from './views/account';
import FoodListingComponent from './views/listing';
import RestaurantsListing from './views/restaurants';
import OrderDetailComponent from './views/orderdetail';
import MyFavouriteComponent  from './views/favourites';
import ResetPasswordComponent from './views/resetpassword';
import ForgetPasswordComponent from './views/forgotpassword';
import TrackOrderComponent from './views/trackorder';
import StandAloneBoxComponent from './views/search';
import { Routes,Route } from "react-router-dom";
import { useLocation } from "react-router-dom"; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from "react";
import i18n from "i18next";
import Constants from "./services/Constants";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs : ['en','fr','ar'], 
    fallbackLng: "en",
    detection:{
      order:['htmlTag','localStorage','path','subdomain'],
      caches:['cookie']
    },
    backend :{
      loadPath: '/locales/{{lng}}/translation.json',
    },
    react : { useSuspense : false}
});

export default function App() {
  const openPage = (pageName) => {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("review_card");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablink");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].style.backgroundColor  = "";
    }
    document.getElementById(pageName).style.display = "block";
    document.getElementById('tablink-'+pageName).style.backgroundColor = "#777";
  }

  const addDefaultSrc = (e,type) => {

    e.target.src = type==="" ?  require(`./assets/images/no_image.jpg`) : require(`./assets/images/no_image_${type}.jpg`);
  }
  
  const location = useLocation();
  const validPages =  ['/login','/register','/verify-phone','/forgotpassword','/resetpassword'];
  return (
    <div className="App">
      {!validPages.includes(location.pathname) ? <HeaderComponent /> : ''}
      {!validPages.includes(location.pathname) ? <ToastContainer  position="bottom-left" autoClose={Constants.TOAST_SECONDS} hideProgressBar={false}
      newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" /> : <ToastContainer theme="colored" />} 
      <Routes>
        <Route path="/" element={<IndexComponent />} />
        <Route path="/search" element={<StandAloneBoxComponent />} />
        <Route path="/time" element={<TimeslotComponent />} />
        <Route path="/error" element={<ErrorComponent />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/orders" element={<MyOrderComponent openPage={openPage} />} />
        <Route path="/contact" element={<ContactComponent />} />
        <Route path="/confirm" element={<ConfirmComponent />} />
        <Route path="/address" element={<MyAddressComponent />} />
        <Route path="/detail/:id" element={<DetailComponent openPage={openPage} addDefaultSrc={addDefaultSrc} />} />
        <Route path="/account" element={<MyAccountComponent addDefaultSrc={addDefaultSrc} />} />
        <Route path="/register" element={<RegisterComponent />} />
        <Route path="/listing/:id/:catname" element={<FoodListingComponent  />} />
        <Route path="/verify-phone" element={<VerifyComponent />} />
        <Route path="/restaurants" element={<RestaurantsListing />} />
        <Route path="/favourites" element={<MyFavouriteComponent openPage={openPage} />} />
        <Route path="/resetpassword" element={<ResetPasswordComponent />} />
        <Route path="/trackorder/:id" element={<TrackOrderComponent />} />
        <Route path="/review/:id" element={<ReviewComponent openPage={openPage} addDefaultSrc={addDefaultSrc} />} />
        <Route path="/orderdetail/:id/:status" element={<OrderDetailComponent addDefaultSrc={addDefaultSrc} />} />
        <Route path="/forgotpassword" element={<ForgetPasswordComponent />} />
        <Route path="/:pageUrl" element={<InfoComponent />} />
      </Routes>
      {!validPages.includes(location.pathname) ? <FooterComponent /> : ''}
    </div>
  );
}
