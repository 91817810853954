import { useState, useEffect } from "react";
import Axios from "axios";

const UseOrders = (url) => {
  const [data, setData] = useState([]);
  const userToken = localStorage.getItem('userToken');
  useEffect(() => {
    Axios.get(url,{
       headers: { 'Authorization': 'Bearer '+userToken }
    }).then((response)=>{
      if(response.data.orders){
        setData(response.data.orders);
      }
    }).catch((error) => {
      console.log(error)
    });
  }, [url,userToken]);

  return [data];
};

export default UseOrders;