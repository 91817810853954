import { TailSpin } from 'react-loader-spinner'
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Constants from "../services/Constants";
import UseFavourites from "../hook/UseFavourites";
import { toast } from 'react-toastify';
import Axios from "axios";

export default function MyFavouriteComponent({openPage}){

  const { t } = useTranslation();
  const [loading,setLoading] = useState(0);
  const [update,setUpdate] = useState(0);
  const favourites = UseFavourites(Constants.FAVOUR_URL,update);
  const userToken = localStorage.getItem('userToken');
  const removeFavoutites = {food_id : 0,restaurant_id : 0};
  const foodLength = favourites.length > 0 ?  favourites.hasOwnProperty("food").length : 0;
  const RestaurantLength = favourites.length > 0 ?  favourites.hasOwnProperty("restaurant").length : 0;
  useEffect(() => {
     setTimeout(() => setLoading(1),Constants.LOADING_SECONDS);
  },[loading,favourites,foodLength,RestaurantLength]);

  
  const convertDateString = (date) => {
    let ChangeString = new Date(date);
    return ChangeString.toDateString();
  }


  const deleteFavourites = (e,type) => {
    var delId = e.target.getAttribute("delete-id");
    if(type===0) removeFavoutites.food_id = delId;
    else removeFavoutites.restaurant_id = delId; 
    Axios.delete(Constants.FAVOUR_REMOVE_URL, { data: removeFavoutites, 
      headers: 
      { 'Authorization': 'Bearer '+userToken } 
    }).then((response) => {
        if(response.data.message)
          setUpdate(update+1);
          toast.success(response.data.message);
    }).catch((error) => {
      console.log(error);
    }).finally((error) => {

    })
  }

  return (
    <main>
    {loading===0 &&
      <div  className="container content-center height-full">
        <TailSpin height="50" width="50" color="#f3723b" ariaLabel="tail-spin-loading"
        radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
      </div>}
    {loading===1 && 
      <>
      <div className="container margin_60_20 margin-30">
        <div className="row">
          <div className="col-md-12">
            <div className="box_order_form">
              <div className="head">
            <h3>{t('my_favourites')}</h3>
          </div>
          <div className="main">
            <div className="row add_bottom_25">
              <button style={{'backgroundColor':'#777'}} className="tablink" id="tablink-food" onClick ={() => openPage('food')}>{t('food')}</button>
              <button className="tablink" id="tablink-restaurant" onClick ={() => openPage('restaurant')}>{t('restaurant')}</button>
              <div id="food" className="review_card col-md-12" >
                <div className="table-responsive">
                  <div className="list_home">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <td>{t('food')}</td>
                          <td>{t('added_on')}</td>
                          <td>{t('action')}</td>
                        </tr>
                      </thead>
                      <tbody>
                        {(favourites.hasOwnProperty("food") && favourites.food.length > 0)
                         && favourites.food.map((food) => {
                            return (
                            <tr key={food.id}>
                              <td><a href={`/detail/${food.id}`}>{food.name}</a></td>
                              <td>{convertDateString(food.created_at)}</td>
                              <td><i onClick={(e) => deleteFavourites(e,0)} delete-id={food.id} className="cursor icon_trash_alt"></i></td>
                            </tr>
                        )})}
                        {(favourites.hasOwnProperty("food") && favourites.food.length===0) && <tr><td colSpan="3"><h6>No result Found</h6></td></tr>}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div id="restaurant" className="review_card col-md-12" style={{'display':'none'}}>
                <div className="table-responsive">
                    <div className="list_home">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <td>{t('restaurant')}</td>
                            <td>{t('added_on')}</td>
                            <td>{t('action')}</td>
                          </tr>
                        </thead>
                        <tbody>
                          {(favourites.hasOwnProperty("restaurant") && favourites.restaurant.length > 0) &&
                            favourites.restaurant.map((restaurant) => {
                              return (
                              <tr key={restaurant.id}>
                                <td><Link to={`/detail/${restaurant.id}`}>{restaurant.name}</Link></td>
                                <td>{convertDateString(restaurant.created_at)}</td>
                                <td><i onClick={(e) => deleteFavourites(e,1)} delete-id={restaurant.id} className="cursor icon_trash_alt"></i></td>
                              </tr>
                          )})}
                          {(favourites.hasOwnProperty("restaurant") && favourites.restaurant.length===0) && <tr><td colSpan="3"><h6>No result Found</h6></td></tr>}
                        </tbody>
                      </table>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>}
  </main>
  )
}