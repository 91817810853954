import { useEffect,useState } from 'react'
import { useParams,useLocation } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import Constants from "../services/Constants";
import Axios from "axios";

export default function InfoComponent(props){
  let { pageUrl } = useParams();
  const location = useLocation();
  const [pagedata,setPageData] =useState([]);
  const [loading,setLoading]= useState(0);

  useEffect(()=>{
    let url = '';
    Axios.get(Constants.CONFIG_URL).then((response) => {
      if(response.data){
        if(pageUrl=='terms-and-conditions'){
          url='terms_and_conditions';
        }
        if(pageUrl=='privacy-policy'){
           url='privacy_policy';
        }
        if(pageUrl=='about-us'){
          url ='about_us';
        }
        if(response.data.hasOwnProperty(url)){
          setPageData(response.data[url]);
        }
      }
    })
    setLoading(1);
  },[pagedata,location.pathname,pageUrl,loading]);

  return (
    <main>
      {loading===0 &&
        <div className="container content-center height-full">
        <TailSpin height="50" width="50" color="#f3723b" ariaLabel="tail-spin-loading"
        radius="1" wrapperStyle={{}} wrapperClass="" visible={true} />
      </div>}
      {(pagedata!==1 && pagedata!==2) && 
      <div className="container margin-80">
        <div className="mt-4">
          <div dangerouslySetInnerHTML={ { __html: pagedata } }></div>
        </div>
      </div>}
    </main>
    );
}

