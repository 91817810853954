import React from 'react'
import ContentLoader from 'react-content-loader'

const CategoryLoader = props => (
  <ContentLoader width={1100} height={220} viewBox="0 0 1100 220"  backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb" {...props} >
    <rect x="0" y="0" rx="2" ry="2" width="270" height="200" />
    <rect x="280" y="0" rx="2" ry="2" width="270" height="200" />
    <rect x="560" y="0" rx="2" ry="2" width="270" height="200" />
    <rect x="840" y="0" rx="2" ry="2" width="270" height="200" />
  </ContentLoader>
)

export default CategoryLoader




