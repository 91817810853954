import { useTranslation } from 'react-i18next';
import { useState, useEffect } from "react";
import Constants from "../services/Constants";

export default function ReviewComponent({reviews}){
  const { t } = useTranslation();
  const [reviewData,setReviewsData] = useState([]);

  useEffect(() => {
    setReviewsData(reviews);
  },[reviews]);

  const addDefaultSrc = (e) => {
    e.target.src = require('../assets/images/no_image_man.png');
  }

  const convertDateString = (date) => {
    let ChangeString = new Date(date);
    return ChangeString.toDateString()+" "+ChangeString.toLocaleTimeString('en',{timeStyle: 'short'});
  }

  return(
      <>
      {Constants.REVIEWS ? 
        <div className="container margin_30_20">
          <div className="row">
            <div className="col-lg-8 list_menu">
              <section id="reviews">
                <h4>Reviews</h4>
                <div id="reviews">
                  {reviews.length > 0 && reviews.map((review,index) => {
                    return(
                      <div key={review.id}  className="review_card padding-30">
                        <div className="row">
                        <div className="col-md-2 user_info">
                          <figure><img onError={addDefaultSrc} src={Constants.CUSTOMER_URL+review.customer.image} alt="" /></figure>
                          <h5>{review.customer.f_name+' '+review.customer.l_name}</h5>
                        </div>
                        <div className="col-md-10 review_content">
                          <h4>{review.food_name}</h4>
                          <div className="clearfix add_bottom_15">
                            <span className="rating">{review.rating}<small>/5</small> <strong>{Constants.RATING_CONTENT[review.rating]}</strong></span>
                            <em>{convertDateString(review.created_at)}</em>
                          </div>
                          <h4>"{review.comment}"</h4>
                        </div>
                      </div>
                    </div>
                  )})}
                  {reviews.length===0 && <p>Not yet reviews</p>}
                </div>
              </section>
            </div>
          </div>
        </div>
        : ''}
      </>
    )
}