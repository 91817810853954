export default function TimeslotComponent(){
    
  var schedules = {opening_time: "10:00",closing_time:"14:00"};

  const getMinutes = s => s.slice(0, 2) * 60 + +s.slice(-2)
  const getTime = m => new Date(m * 6e4).toJSON().slice(11, 16)


  const getTimeSlots = (starts,end,minDifference,day,schedules) => {
    var result = [];
    if(schedules.hasOwnProperty("opening_time") || schedules.hasOwnProperty("closing_time")){
      var start = getMinutes(starts), end = getMinutes(end);
      result.push('<select name="schedule_time">');
      for (var m = start; m <= end - 60; m += 30) 
        result.push('<option value="'+getTime(m) + '-' + getTime(m + 60)+'">'+getTime(m) + '-' + getTime(m + 60)+'</option>');
      
      result.push('</select>');
    }
    return result;
  }

  return(
    <div className="container" style={{'marginTop':'100px'}}>
      {getTimeSlots(schedules.opening_time,schedules.closing_time,30,4,schedules)}
      </div>
    )
}