import { Link,useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import Constants from "../services/Constants"; 
import Axios from "axios";
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useEffect,useState } from "react";
import $ from 'jquery'; 

export default function RegisterComponent(){
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { register, handleSubmit,setValue,setError, formState: { errors } } = useForm();
  const [phone, setPhone] = useState("");
  const [submit,setSubmit] =useState(0);

  const onSubmit = (data) => {
    $('.spinner').css({'display':'inline-flex'});
	  Axios.post(Constants.REGISTER_URL,{
	  	f_name : data.f_name,l_name: data.l_name,email : data.email,
	  	phone: '+'+data.phone,password:data.password
	  }).then((response) => {
      if(response.data){
        localStorage.setItem('otp',response.data.otp);
        if(response.data.phone_verify_end_url){
          var url = response.data.phone_verify_end_url.split('auth');
          var verify = url[1];
    	  	navigate(verify,{state:{notify:true,success:true,message:t('otp_sent_success')}});
        }
      }
	  }).catch((errors) => {
      if(errors){
        if(typeof errors.response.data.errors === 'object'){
          var Arr = errors.response.data.errors;
          for (var i = 0; i < Arr.length; i++) { 
            if(Arr[i].code!=='' && Arr[i].message!==''){
              var message = Arr[i].message.charAt(0).toUpperCase() + Arr[i].message.slice(1);
              toast.error(message); 
            }
          }
        }
      }
	  }).finally(() => {
      $('.spinner').css({'display':'none'});
	  });
  }

  if(submit){
    if(phone.length>0 || phone==='') setValue('phone',phone);
    if(errors.phone?.type==="required" || phone.length===0) setError("phone", { type: "required" });
    setSubmit(0);
  }

	return (
		<div id="register_bg">
			<div id="register">
				<aside>
					<figure>
						<Link to="/"><img src={localStorage.getItem('logo')} width="140" height="35" alt="" /></Link>
					</figure>
					<></>
					<form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<div className="form-group">
              <label>{t('first_name')}</label>
							<input 
							{...register("f_name", 
								{ required: true,
									pattern: /^[A-Za-z\s]*$/,
                  maxLength: 16, 
                  minLength: 3
                }
              )}
							className="form-control" type="text" placeholder={t('enter_your_first_name')} />
							<i className="icon_pencil-edit"></i>
							{errors.f_name?.type==="required" && <p className="error">{t('firstname_is_required')}</p>}
							{errors.f_name?.type==="maxLength" && <p className="error">{t('firstname_is_maxlength')}</p>}                 	
        			{errors.f_name?.type==="pattern" && <p className="error">{t('firstname_is_invalid')}</p>}                 	
              {errors.f_name?.type==="minLength" && <p className="error">{t('firstname_is_minlength')}</p>}
						</div>
						<div className="form-group">
              <label>{t('last_name')}</label>
							<input 
							{...register("l_name", 
								{ 
									required: true, 
                  pattern: /^[A-Za-z\s]*$/,
                  maxLength: 16, 
                  minLength: 3
                }
              )}
							className="form-control" type="text" placeholder={t('enter_your_last_name')} />
							<i className="icon_pencil-edit"></i>
							{errors.l_name?.type==="required" && <p className="error">{t('lastname_is_required')}</p>}
							{errors.l_name?.type==="maxLength" && <p className="error">{t('lastname_is_maxlength')}</p>}                 	
      				{errors.l_name?.type==="pattern" && <p className="error">{t('lastname_is_invalid')}</p>}  
              {errors.l_name?.type==="minLength" && <p className="error">{t('lastname_is_minlength')}</p>}               
						</div>
						<div className="form-group">
              <label>{t('email')}</label>
							<input
							{...register("email", 
							{ 	required : true,
									pattern: {
										value : /^\S+@\S+$/i,
									}
							})}
							 className="form-control" type="text" placeholder={t('enter_your_email_address')} />
							<i className="icon_mail_alt"></i>
							{errors.email?.type==="required" && <p className="error">{t('email_is_required')}</p>}
							{errors.email?.type==="pattern" && <p className="error">{t('email_is_invalid')}</p>}                 	          
      				{errors.email?.type==="validate" && <p className="error">{t('email_is_validate')}</p>}                 	          
						</div>
						<div className="form-group">
              <label>{t('mobile_no')}</label>
              <input {...register("phone",{required: true})} className="form-control" value={phone || ''} type="hidden" placeholder={t('enter_your_mobile_no')} />
              <PhoneInput  placeholder={t('enter_your_mobile_no')} enableSearch={true} country={Constants.DEFAULT_PHONE_CODE} value={phone}  onChange={(phone) => setPhone(phone)} />
							{(errors.phone?.type==="required" && phone.length===0) && <p className="error">{t('mobile_no_is_required')}</p>}
              {phone.length > 14 && <p className="error">{t('mobile_no_is_maxlength')}</p>}
              {(phone.length > 0 && phone.length < 11) && <p className="error">{t('mobile_no_is_minlength')}</p>}
						</div>
						<div className="form-group">
              <label>{t('password')}</label>
							<input
							{...register("password", 
								{ 
									required: true, 
                  minLength: 6, 
                  maxLength: 15,  
                }
              )}
							 className="form-control" type="password" id="password2" placeholder={t('enter_your_password')} />
							<i className="icon_lock_alt"></i>
							{errors.password?.type==="required" && <p className="error">{t('password_is_required')}</p>}
              {errors.password?.type==="minLength" && <p className="error">{t('password_is_minlength')}</p>}
              {errors.password?.type==="maxLength" && <p className="error">{t('password_is_maxlength')}</p>}
						</div>
						<div id="pass-info" className="clearfix"></div>
						<button onClick={() => setSubmit(1)} className="btn_1 gradient full-width"><span className="spinner"></span>&nbsp;&nbsp;{t('register_now')}</button>
						<div className="text-center mt-2"><small>{t('already_have_an_account')}<strong>
							<Link to="/login">{t('sign_in')}</Link></strong></small>
						</div>
            <div className="text-center mt-2 ft-12"><span><strong><Link to="/">&lt;&lt; {t('back_to_home')}</Link></strong></span></div>
					</form>
				</aside>
			</div>
		</div>
	)
}