const APP_URL = 'http://localhost:3000/';
const ADMIN_URL = 'https://baza.bi/';
// const ADMIN_URL1 = 'https://baza.bi/';
const API_URL = ADMIN_URL+'api/web';
const IMAGE_URL = ADMIN_URL+'backend/storage/app/public/';
const PRODUCT_URL = ADMIN_URL+'backend/storage/app/public/product/';
const CUSTOMER_URL = ADMIN_URL+'backend/storage/app/public/profile/';
const BANNER_URL = ADMIN_URL+'backend/storage/app/public/banner/';
const CATEGORY_URL = ADMIN_URL+'backend/storage/app/public/category/';
const REVIEW_URL = ADMIN_URL+'backend/storage/app/public/review/';
const NOTIFY_URL = ADMIN_URL+'backend/storage/app/public/notification/';
const RESTAURANT_URL = ADMIN_URL+'backend/storage/app/public/restaurant/';
const VENDOR_URL = ADMIN_URL+'backend/storage/app/public/vendor/';
const RESTAURANT_PHOTO_URL = ADMIN_URL+'backend/storage/app/public/restaurant/cover/';
const DELIVERY_URL = ADMIN_URL+'backend/storage/app/public/delivery-man/';
const CHAT_URL = ADMIN_URL+'backend/storage/app/public/conversation/';
const CAMPAIGN_URL = ADMIN_URL+'backend/storage/app/public/campaign/';
const BUSINESS_URL = ADMIN_URL+'backend/storage/app/public/business/';

const GOOGLE_MAP_URL = 'https://maps.google.com/maps/dir/';
const GOOGLE_ADDRESSMAP_URL = 'https://maps.google.com/maps?q=';
const GOOGLE_ADDRESSMAP_URL_CUSTOM = '&hl=es&z=14&amp;output=embed';
const LOGO = 'https://baza.bi/backend/storage/app/public/business/2022-11-18-63777a2a92d14.png';

const CONFIG_URL = API_URL+'/config';
const FORGET_PASS_URL = API_URL+'/auth/forgot-password';
const RESET_PASS_URL = API_URL+'/auth/reset-password';
const LOGIN_URL = API_URL+'/auth/login';
const REGISTER_URL = API_URL+'/auth/register';
const VERIFY_PHONE_URL = API_URL+'/auth/verify-phone';
const LOC_API_URL= API_URL+'/config/get-zone-id';
const CAT_API_URL= API_URL+'/categories';
const USERINFO_API_URL= API_URL+'/customer/info';
const UPDATE_INFO_API_URL= API_URL+'/customer/update-profile';
const GETORDERS_API_URL= API_URL+'/customer/order/list';
const GETORDERS_RUNNING_API_URL= API_URL+'/customer/order/running-orders';
const ORDERS_DETAIL_API_URL= API_URL+'/customer/order/details';
const GETADDRESS_URL= API_URL+'/customer/address/list';
const DELADDRESS_URL= API_URL+'/customer/address/delete';
const FAVOUR_URL= API_URL+'/customer/wish-list';
const FAVOURITES_FOOD_RESTAURANT= API_URL+'/customer/wish-list/list';
const FAVOUR_ADD_URL= API_URL+'/customer/wish-list/add';
const FAVOUR_REMOVE_URL= API_URL+'/customer/wish-list/remove';
const GETREVIEWS_URL= API_URL+'/restaurants/reviews';
const RESTAURANT_ALL_URL= API_URL+'/restaurants/get-restaurants/';
const CATEGORY_RESTAURANTS= API_URL+'/categories/restaurants/';
const RESTAURANT_POPULAR_URL= API_URL+'/restaurants/popular';
const RESTAURANT_DETAIL_URL= API_URL+'/restaurants/details/';
const RESTAURANT_REVIEW_URL= API_URL+'/restaurants/reviews';
const RESTAURANT_PRODUCT_URL= API_URL+'/categories/products/';
const GETRESTAURANT_PRODUCT_URL= API_URL+'/categories/get-restaurant-products/';
const ADD_ADDRESS_URL= API_URL+'/customer/address/add';
const GET_TRACKORDER_URL = API_URL+'/customer/order/track';
const APPLY_COUPON_CODE = API_URL+'/coupon/apply';

const AUTH = 0;
const REVIEWS = 0;
const CLEAR_ZONE  = 'block';
// const CLEAR_ZONE  = 'none';
const DEFAULT_ZONE_ID = 2;
const DEFAULT_PHONE_CODE  = 'bi';
const LOADING_SECONDS = 1000;
const TOAST_SECONDS = 1000;
const MODALCLOSE_SECONDS = 3000;
const REACT_APP_GOOGLE_API_KEY="AIzaSyCsOaW30FXZ1Wl0TlmuZp9Pz6Azo-CF_QE";
const REACT_GOOGLE_LIBRARY = ['geometry', 'drawing','places'];
const ClearStorage = ['userToken','zoneId','user','userImage','userName','otp','phone'
,'userVerified','userAddress','cart','previousResId','currentResId','deliveryFees','sum'];
const RESTAURANT_TYPES = ['All','Take_away','Delivery'];
const ORDER_TYPE = ['takeaway','delivery'];
const ORDER_STATUS_TYPES = ['pending','confirmed','processing','picked_up','handover','delivered'];
const RATING_COUNT = [1,2,3,4,5];
const ADDRESS_TYPES = ['home','office','others'];
const FOOTER_MENU = ['terms-and-conditions','privacy-policy','about-us'];
const MONTH_NAMES = [ "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December" ];
const STATUS_COLOR = {'pending':'warning','confirmed':'primary',
'processing':'secondary','picked_up':'dark','handover':'info',
'delivered':'success','delivery_boy_delivered':'info',
'accepted':'primary','canceled':'danger','refunded':'dark'};
const ORDER_DAYS_COUNT = [{'day':0},{'day':1},{'day':2},{'day':3},{'day':4},
{'day':5},{'day':6}];
const ORDER_DAYS = ['Today','Tomorrrow'];
const RATING_CONTENT = ['','Bad','Not Bad','Average','Good','Excellent'];

const Constants = {
    APP_URL, API_URL,ADMIN_URL,IMAGE_URL,
    PRODUCT_URL,CUSTOMER_URL,BANNER_URL,
    CATEGORY_URL,REVIEW_URL,NOTIFY_URL,
    RESTAURANT_URL,VENDOR_URL,RESTAURANT_PHOTO_URL,
    DELIVERY_URL,CHAT_URL,CAMPAIGN_URL,BUSINESS_URL,
    LOC_API_URL,CAT_API_URL,USERINFO_API_URL,
    UPDATE_INFO_API_URL,CONFIG_URL,FORGET_PASS_URL,
    LOGIN_URL,REGISTER_URL,VERIFY_PHONE_URL,
    RESTAURANT_ALL_URL,RESET_PASS_URL,
    GETORDERS_API_URL,GETADDRESS_URL,DELADDRESS_URL,
    FAVOUR_URL,FAVOUR_REMOVE_URL,GETREVIEWS_URL,
    RESTAURANT_POPULAR_URL,RESTAURANT_DETAIL_URL,
    RATING_COUNT,GOOGLE_MAP_URL,ClearStorage,
    RESTAURANT_TYPES,RESTAURANT_PRODUCT_URL,MODALCLOSE_SECONDS,
    GETRESTAURANT_PRODUCT_URL,FAVOUR_ADD_URL,TOAST_SECONDS,
    REACT_APP_GOOGLE_API_KEY,LOGO,GOOGLE_ADDRESSMAP_URL,
    GOOGLE_ADDRESSMAP_URL_CUSTOM,ADD_ADDRESS_URL,ADDRESS_TYPES,
    DEFAULT_PHONE_CODE,ORDERS_DETAIL_API_URL,LOADING_SECONDS,
    GETORDERS_RUNNING_API_URL,MONTH_NAMES,STATUS_COLOR,
    DEFAULT_ZONE_ID,RESTAURANT_REVIEW_URL,GET_TRACKORDER_URL,
    ORDER_STATUS_TYPES,CLEAR_ZONE,FOOTER_MENU,REACT_GOOGLE_LIBRARY,
    FAVOURITES_FOOD_RESTAURANT,ORDER_DAYS_COUNT,ORDER_DAYS,ORDER_TYPE,
    APPLY_COUPON_CODE,RATING_CONTENT,CATEGORY_RESTAURANTS,AUTH,REVIEWS
}


export default Constants;