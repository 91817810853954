import React from 'react'
import ContentLoader from 'react-content-loader'

const ListRestaurantLoader = props => (
  <ContentLoader width={1140} height={133} viewBox="0 0 1140 133" backgroundColor="#f5f5f5"
    foregroundColor="#dbdbdb" {...props}>
    <rect x="0" y="0" rx="0" ry="0" width="200" height="133" />
    <rect x="560" y="0" rx="0" ry="0" width="200" height="133" />
    <rect x="200" y="0" rx="0" ry="0" width="340" height="2" />
    <rect x="760" y="0" rx="0" ry="0" width="340" height="2" />
    <rect x="220" y="25" rx="0" ry="0" width="100" height="6" />
    <rect x="780" y="25" rx="0" ry="0" width="100" height="6" />
    <rect x="220" y="45" rx="0" ry="0" width="120" height="6" />
    <rect x="780" y="45" rx="0" ry="0" width="120" height="6" />
    <rect x="220" y="65" rx="0" ry="0" width="160" height="6" />
    <rect x="780" y="65" rx="0" ry="0" width="160" height="6" />
    <rect x="220" y="85" rx="0" ry="0" width="80" height="20" />
    <rect x="780" y="85" rx="0" ry="0" width="80" height="20" />
    <rect x="310" y="85" rx="0" ry="0" width="160" height="20" />
    <rect x="870" y="85" rx="0" ry="0" width="160" height="20" />
    <rect x="200" y="130" rx="0" ry="0" width="340" height="2" />
    <rect x="760" y="130" rx="0" ry="0" width="340" height="2" />
    <rect x="540" y="0" rx="0" ry="0" width="2" height="130" />
    <rect x="1100" y="0" rx="0" ry="0" width="2" height="130" />
  </ContentLoader>
)

export default ListRestaurantLoader